import {
  IHero,
  dataHero,
  storiesFirstAltTag,
  storiesFirstBackground,
  storiesFirstTitleTag,
} from "@/data/hero.data";
import { dataMenuLabels } from "@/data/menu.data";
import { SectionElement, Stories } from "@components/index";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import styles from "./stories-section.module.scss";
import Image from "next/image";

const MainStories: FC = observer(() => {
  const items: IHero[] | any = [];
  items.push({
    showNext: true,
    title: storiesFirstTitleTag,
    body: (
      <div className={styles.slides}>
        <div
          className={styles.slidesContainer}
          title={storiesFirstTitleTag}
        >
          <Image
            src='/images/ui/selo-mais-vendido.svg'
            width={200}
            height={120}
            alt="Selo Mais Vendido"
          />
        </div>
      </div>
    ),
    scrollCtaOrientation: "vertical",
    background: {
      src: storiesFirstBackground,
      alt: storiesFirstAltTag,
    },
    durationInS: 15,
  });
  dataHero.map((item) =>
    items.push({
      showNext: true,
      title: item.titleStories,
      subtitle: item.title,
      description: item.descriptionStories,
      body: (
        <>
          <div className={styles.slides}>
            <div className={styles.slidesContainer}>
              <div className={styles.bottomBox}>
                <h2>{item.titleStories}</h2>
                <p className={styles.contentText}>{item.descriptionStories}</p>
              </div>
            </div>
          </div>
        </>
      ),
      scrollCtaOrientation: "vertical",
      background: {
        src: item.backgrounds[1],
        alt: item.altTag,
      },
      durationInS: 15,
    })
  );
  return (
    <SectionElement
      id="mobileStories"
      navReference={dataMenuLabels()[0]}
      className={styles.container}
      noPadding
    >
      <Stories items={items} />
    </SectionElement>
  );
});

export default MainStories;
